/* eslint-disable no-var */
let ue;
if (typeof ue != "object" || typeof ue.interface != "object") {
  if (typeof ue != "object") ue = {};

  // mobile
  ue.interface = {};
  ue.interface.broadcast = function(name, data) {
    if (typeof name != "string") return;
    var args = [name, ""];
    if (typeof data != "undefined") args[1] = data;

    var hash = encodeURIComponent(JSON.stringify(args));
    if (typeof history == "object" && typeof history.pushState == "function") {
      history.pushState({}, "", "#" + hash);
      history.pushState({}, "", "#" + encodeURIComponent("[]"));
    } else {
      document.location.hash = hash;
      document.location.hash = encodeURIComponent("[]");
    }
  };
} else
  (function(obj) {
    // desktop
    ue.interface = {};
    ue.interface.broadcast = function(name, data) {
      if (typeof name != "string") return;
      if (typeof data != "undefined") obj.broadcast(name, JSON.stringify(data));
      else obj.broadcast(name, "");
    };
  })(ue.interface);

// create the global ue4(...) helper function
module.exports = {
  ue4: ue.interface.broadcast
};
