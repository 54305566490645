
import AlertItem from "@/components/AlertItem.vue";
import AlertBtnFilter from "@/components/buttons/AlertBtnFilter.vue";
import CameraInfoItem from "@/components/CameraInfoItem.vue";
import SummaryPanel from "@/components/SummaryPanel.vue";
import Toolbar from "@/components/Toolbar.vue";
import InfiniteLoading, { StateChanger } from "vue-infinite-loading";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { ApiDataHandler } from "./utils/handler";
import {
  AlertBtnType,
  ALERT_LEVEL,
  CameraInfo,
  FilterInfo,
  IoTHubAAA,
  UserFilter,
  UserInputData
} from "./utils/type";

import { ue4 } from "@/lib/ue4";
import {
  CompleteAlert,
  AcknowledgeAlert,
  FalseAlarm,
  initWebSocket
} from "./utils/api";

const FILTER_DATA: FilterInfo[] = [
  {
    name: "Alert",
    type: "default",
    values: [
      AlertBtnType.NOTIFICATION,
      AlertBtnType.WARNING,
      AlertBtnType.ALERT
    ],
    itemValue: null
  }
];

@Component({
  components: {
    // Header,
    Toolbar,
    SummaryPanel,
    CameraInfoItem,
    AlertItem,
    AlertBtnFilter,
    InfiniteLoading
  }
})
export default class App extends Vue {
  @Ref() readonly toolbar!: Toolbar;
  lastUpdate = Date.now();

  /** State */
  userInput: UserInputData = {
    layout: "grid",
    timezone: "",
    searchText: "",
    userFilters: [
      {
        activeItem: "Alert",
        activeValues: [null]
      }
    ]
  };
  historyAPI = "";
  showrtsp = false;
  currentLayout = "list";
  cameraList: CameraInfo[] = [];
  alertDataPool: IoTHubAAA[] = [];
  counts: { [key: string]: number } = {};
  dateNow = new Date();
  filterInfos: FilterInfo[] = FILTER_DATA;
  activeNotificationId = "";
  isLoading = false;
  isInfiniteLoading = false;
  errMessage = "";
  theme = "light";
  get timezoneOffset() {
    return 8;
  }

  get loaderId() {
    return Date.now() / 60000;
  }

  liveDataWs: WebSocket | null = null;

  get isDevelopment() {
    return process.env.NODE_ENV === "development";
  }

  get AlertBtnType() {
    return AlertBtnType;
  }

  get projectId() {
    if (this.searchParams.has("project_id"))
      return this.searchParams.get("project_id") || -1;
    // if not found then set project id to 400 (not exist project)
    return this.isDevelopment ? 400 : -1;
  }

  get totalCount() {
    return Object.keys(this.counts).reduce((sum, key) => {
      const k = key as AlertBtnType;
      const count = this.counts[k] || 0;
      return sum + count;
    }, 0);
  }

  get filteredCount() {
    return this.filteredDataPool.length;
  }

  get activeBtnFilter() {
    if (this.alertTypeFilter) {
      return this.alertTypeFilter.activeValues[0];
    }
    return null;
  }

  get searchParams() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams;
  }

  @Watch("searchParams")
  onSearchParamsChanged(val: URLSearchParams) {
    console.log("new search params have become", val);
  }

  @Watch("activeBtnFilter")
  onActiveFilterChanged() {
    console.log("ACTIVE BTN FILTER CHANGES TO " + this.activeBtnFilter);
    if (
      this.filteredDataPool.length <
        (this.counts as any)[this.activeBtnFilter] ||
      0
    ) {
      console.log(
        "Filtered item less then expected. Try refreshing in 1 second"
      );
      setTimeout(() => {
        this.refresh();
      }, 1000);
    }
  }

  get filteredDataPool() {
    if (this.activeBtnFilter) {
      return this.alertDataPool.filter(a => a.level === this.activeBtnFilter);
    }
    return this.alertDataPool;
  }

  infiniteHandler($state: StateChanger) {
    if (this.isInfiniteLoading) return;
    this.isInfiniteLoading = true;
    setTimeout(() => {
      this.refresh()
        .then(ret => {
          if (!ret) $state.error();
          if (ret) $state.loaded();
          if (this.activeBtnFilter) {
            if (
              this.filteredDataPool.length ===
              (this.counts as any)[this.activeBtnFilter]
            )
              $state.complete();
          } else {
            if (this.alertDataPool.length === this.totalCount)
              $state.complete();
          }
        })
        .finally(() => {
          this.isInfiniteLoading = false;
        });
      this.isInfiniteLoading = false;
    }, 1000);
  }

  get lastUpdateText() {
    const d = new Date(this.lastUpdate);
    return `Last Update: ${d.toDateString()}`;
  }

  get debugUrl() {
    return window.location.href;
  }

  onMessage(data: IoTHubAAA): void {
    if (data) {
      console.log("Prepend to alertDataPool");
      try {
        const timestamp = new Date(data.timestamp);
        data.timestamp = timestamp.toLocaleString();
        const newData = JSON.parse(JSON.stringify(data));
        this.alertDataPool.unshift(newData);
      } catch (err) {
        console.error(err);
        this.alertDataPool.unshift(data);
      }

      if (data.level) {
        const value = this.counts[data.level] || 0;
        console.log(`Update count of ${data.level} to ${value + 1}`);
        Vue.set(this.counts, data.level, value + 1);
      }

      this.errMessage = "";
    }
  }

  /** Life cycle */
  created() {
    const params = new URLSearchParams(window.location.search);
    const themeParam = params.get("theme");
    this.theme = themeParam === "dark" ? "dark" : "light";

    document.body.classList.add(this.theme);

    this.refresh()
      .then(() => {
        if (this.searchParams.get("cameraId")) {
          console.log(this.searchParams);
        }
      })
      .finally(() => {
        this.isLoading = false;
      });

    initWebSocket(+this.projectId, this.onMessage)
      .then(ws => {
        if (!ws) throw Error("Alert Center: cannot get web socket reference");
        this.liveDataWs = ws;
        console.log("Mounted: store web socket object");
      })
      .catch(err => {
        console.error(err);
      });
  }

  /** Controller */

  get alertTypeFilter() {
    const filter = this.userInput.userFilters.find(
      filter => filter.activeItem === "Alert"
    );
    return filter;
  }

  async pullAlertItem(id: string) {
    console.log("Pull id =", id);
    const idx = this.alertDataPool.findIndex(item => item._event_id === id);
    if (idx > -1) {
      const item = this.alertDataPool.splice(idx, 1);
      if (item[0].level)
        this.counts[item[0].level] = (this.counts[item[0].level] || 0) - 1;
    }
    const ret = await CompleteAlert(id);
    console.log("pullAlertItem - server result=", ret);
  }

  async falseAlarmItem(id: string) {
    console.log("Pull id =", id);
    const idx = this.alertDataPool.findIndex(item => item._event_id === id);
    if (idx > -1) {
      const item = this.alertDataPool.splice(idx, 1);
      if (item[0].level)
        this.counts[item[0].level] = (this.counts[item[0].level] || 0) - 1;
    }
    await FalseAlarm(id);
  }

  async acknowledgeAlertItem(id: string) {
    console.log("Pull id =", id);
    const idx = this.alertDataPool.findIndex(item => item._event_id === id);
    if (idx > -1) {
      const item = this.alertDataPool.splice(idx, 1);
      if (item[0].level)
        this.counts[item[0].level] = (this.counts[item[0].level] || 0) - 1;
    }
    await AcknowledgeAlert(id);
  }

  setActiveAlertType(type: AlertBtnType, broadcast = true) {
    console.log(
      `Set active type to '${type}', current active values= ${this.activeBtnFilter}`
    );
    if (!this.alertTypeFilter) {
      console.error("=> setActiveAlertType ERROR: filter item not found");
      return;
    }
    const filter: UserFilter = { ...this.alertTypeFilter };
    let resetFilter = false;
    if (!filter.activeValues || filter.activeValues[0] === null) {
      console.log("=> active value set");
      filter.activeValues = [type];
    } else if (filter.activeValues[0] != type) {
      console.log("=> active value update");
      filter.activeValues = [type];
    } else if (filter) {
      console.log("=> active value reset");
      resetFilter = true;
      filter.activeValues = [null];
    }
    if (broadcast && !resetFilter) {
      console.log("notify ue4 on filter update");
      switch (type) {
        case AlertBtnType.NOTIFICATION:
          console.log("ue4: filter_info");
          ue4("filter_info", {});
          break;
        case AlertBtnType.WARNING:
          console.log("ue4: filter_warn");
          ue4("filter_warn", {});
          break;
        case AlertBtnType.ALERT:
          console.log("ue4: filter_alert");
          ue4("filter_alert", {});
          break;
      }
    } else if (broadcast) {
      console.log("ue4: filter_all");
      ue4("filter_all", {});
    }
    Vue.set(this.userInput, "userFilters", [filter]);
    console.log(`==> updated active values = ${this.activeBtnFilter}`);
  }

  resetApp() {
    this.activeNotificationId = "";
    this.currentLayout = this.userInput.layout || "grid";
    this.userInput.userFilters = [];
    this.searchParams.delete("cameraId");
    console.log("updating search params");
    history.replaceState(null, "", "");
  }

  async refresh(): Promise<boolean> {
    const apiConfig = {
      // offset: this.alertDataPool.length,
      alertLevel: ALERT_LEVEL.NOTIFICATION,
      offset: 0,
      projectId: this.projectId
    };

    switch (this.activeBtnFilter) {
      case AlertBtnType.NOTIFICATION:
        apiConfig.alertLevel = ALERT_LEVEL.NOTIFICATION;
        break;
      case AlertBtnType.WARNING:
        apiConfig.alertLevel = ALERT_LEVEL.WARNING;
        break;
      case AlertBtnType.ALERT:
        apiConfig.alertLevel = ALERT_LEVEL.ALERT;
        break;
    }

    if (apiConfig.alertLevel) {
      // if filter is applied, use filtered offset
      apiConfig.offset = this.filteredDataPool.length;
    }

    const ret = await ApiDataHandler(apiConfig);

    if (!ret) {
      console.error("Cannot get data");
      return false;
    } else if (ret.message && !ret.events) {
      this.errMessage = `Fail to get data: ${ret.message}`;
      return false;
    } else {
      Vue.set(this.counts, AlertBtnType.NOTIFICATION, ret.notificationCount);
      Vue.set(this.counts, AlertBtnType.WARNING, ret.warningCount);
      Vue.set(this.counts, AlertBtnType.ALERT, ret.alertCount);

      if (Array.isArray(ret.events) && ret.events.length > 0) {
        ret.events.forEach(e => {
          e.timestamp = new Date(e.timestamp).toLocaleString();
        });
        // avoid pushing duplicated event
        this.alertDataPool.push(
          ...ret.events.filter(e =>
            this.alertDataPool.every(a => a._event_id !== e._event_id)
          )
        );
      }
    }
    this.isLoading = false;
    return true;
  }

  ue4(url: string, camId: string) {
    console.log(`ue4: show_cctv, url=${url}, id=${camId}`);
    ue4("show_cctv", { url: url, id: camId });
  }
}
