
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import { FilterInfo, ThemeConfig } from "../../utils/type";
import { DEFAULT_THEME } from "../../utils/constants";
import { hexToRgb } from "@/utils/helper";

const DEFAULT_FILTERINFO: FilterInfo = {
  name: "",
  type: "default",
  values: [],
  itemValue: ""
};

@Component
export default class ToolbarFilterItem extends Vue {
  @Prop({ default: false }) readonly active!: boolean;
  @Prop({ default: () => DEFAULT_FILTERINFO }) filterInfo!: FilterInfo;
  @Prop({ default: () => DEFAULT_THEME }) readonly theme!: ThemeConfig;

  get filterName() {
    return this.filterInfo.itemValue
      ? `${this.filterInfo.name} - ${this.filterInfo.itemValue}`
      : this.filterInfo.name;
  }

  get itemClass() {
    return {
      active: this.active
    };
  }

  get itemStyle() {
    const style: any = {};
    const bgColor = this.theme.primaryColor || "#888888";
    const rgb = hexToRgb(bgColor);
    let color = "#000000";
    if (rgb) {
      const brightness = Math.round(
        (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
      );
      if (brightness < 125) color = "#ffffff";
    }
    style["--background-color"] = bgColor;
    style["--color"] = color;
    if (this.active) {
      style["backgroundColor"] = bgColor;
      style["color"] = color;
    }
    return style;
  }

  @Emit()
  click() {
    return this.filterInfo;
  }
}
