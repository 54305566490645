
import { Component, Prop, Emit, Vue } from "vue-property-decorator";

@Component
export default class ToolbarBtnLayout extends Vue {
  @Prop({ default: "list" }) readonly layout!: string;

  get currentLayout() {
    return {
      [this.layout]: true
    };
  }

  @Emit()
  click() {
    return true;
  }
}
