
import { Scrollable } from "@/utils/helper";
import { FilterInfo, UserFilter } from "@/utils/type";
import { Component, Prop, PropSync, Ref, Vue } from "vue-property-decorator";
import ToolbarFilterItem from "./buttons/ToolbarFilterItem.vue";

@Component({
  components: {
    ToolbarFilterItem
  }
})
export default class ToolbarFilterPanel extends Vue {
  @Prop({ default: false }) readonly expand!: boolean;
  @Prop({ default: () => [] }) readonly filterList!: FilterInfo[];
  @PropSync("userFilters", { type: Array })
  readonly $_userFilters!: UserFilter[];
  @Ref("sublist1") readonly elSublist1!: HTMLElement;
  @Ref("sublist2") readonly elSublist2!: HTMLElement;

  scrollableLists: Scrollable[] = [];

  get itemClass() {
    return {
      expand: this.expand
    };
  }

  get mainFilters() {
    return this.filterList.filter(filter => filter.active);
  }

  get subFilterList1() {
    return [];
  }

  get subFilterList2() {
    return this.filterList.filter(filter => filter.name === "Alert");
  }

  mounted() {
    this.scrollableLists.push(
      new Scrollable(this.elSublist1, "125, 125, 125", true, true)
    );
    this.scrollableLists.push(
      new Scrollable(this.elSublist2, "125, 125, 125", true, true)
    );
  }

  unmounted() {
    this.scrollableLists.forEach(scrollable => {
      scrollable.destroy();
    });
  }

  onFilterItemClicked(filter: FilterInfo) {
    // console.log("onFilterItemClicked:", filter);
    // clean filter if it is active
    if (filter.active) {
      console.log("update filter:" + filter.name);
      const filterItem = this.$_userFilters.find(
        f => f.activeItem === filter.name
      );

      if (filterItem && filterItem.activeValues.includes(filter.itemValue)) {
        if (filterItem.activeValues.length === 1) {
          console.log("remove filter item");
          this.$emit(
            "update:userFilters",
            this.$_userFilters.filter(f => f.activeItem !== filter.name)
          );
        } else {
          console.log("remove filter value: " + filter.itemValue);
          this.$emit(
            "update:userFilters",
            this.$_userFilters.map(f => {
              if (f.activeItem == filter.name) {
                return {
                  activeItem: filter.name,
                  activeValues: f.activeValues.filter(
                    val => val !== filter.itemValue
                  )
                };
              }
              return f;
            })
          );
        }
      }
      this.$emit(
        "update:userFilters",
        this.$_userFilters.filter(f => f.activeItem !== filter.name)
      );
    } else {
      if (this.$_userFilters.some(f => f.activeItem === filter.name)) {
        console.log("add filter value: " + filter.itemValue);
        const newFilters = this.$_userFilters.map(f => {
          if (f.activeItem === filter.name) {
            return {
              activeItem: filter.name,
              activeValues: f.activeValues.concat([filter.itemValue])
            };
          }
          return f;
        });
        this.$emit("udpate:userFilters", newFilters);
      } else {
        console.log("add filter item: " + filter.name);
        const newFilters = this.$_userFilters.concat([
          { activeItem: filter.name, activeValues: [filter.itemValue] }
        ]);
        this.$emit("update:userFilters", newFilters);
      }
    }
  }
}
