
import { Component, Prop, Emit, Vue } from "vue-property-decorator";

@Component
export default class ToolbarBtnFilter extends Vue {
  @Prop({ default: false }) readonly isFilterPanelActive!: boolean;

  get itemClass() {
    return {
      active: this.isFilterPanelActive
    };
  }

  @Emit()
  click() {
    return true;
  }
}
