
import ToolbarBtnFilter from "@/components/buttons/ToolbarBtnFilter.vue";
import ToolbarBtnLayout from "@/components/buttons/ToolbarBtnLayout.vue";
import ToolbarSearchField from "@/components/ToolbarSearchField.vue";
import ToolbarFilterItem from "@/components/buttons/ToolbarFilterItem.vue";
import ToolbarFilterPanel from "@/components/ToolbarFilterPanel.vue";

import {
  Component,
  Prop,
  Emit,
  Ref,
  Vue,
  PropSync
} from "vue-property-decorator";
import { FilterInfo, UserFilter, UserInputData } from "@/utils/type";
import { Scrollable } from "@/utils/helper";

@Component({
  components: {
    ToolbarBtnFilter,
    ToolbarBtnLayout,
    ToolbarSearchField,
    ToolbarFilterPanel,
    ToolbarFilterItem
  }
})
export default class Toolbar extends Vue {
  @Prop({ default: "list" }) readonly layout!: string;
  @Prop({ default: () => [] }) readonly filterInfos!: FilterInfo[];
  @PropSync("userInput", { type: Object }) readonly $_userInput!: UserInputData;
  @Ref("filterList") readonly elFilterList!: HTMLElement;

  get searchText() {
    return this.$_userInput && "searchText" in this.$_userInput
      ? this.$_userInput.searchText
      : "";
  }
  set searchText(val: string) {
    // console.log("Toolbar: set userInput.searchText to", val);
    Vue.set(this.$_userInput, "searchText", val);
  }

  get userFilters() {
    return this.$_userInput && this.$_userInput.userFilters
      ? this.$_userInput.userFilters
      : [];
  }

  set userFilters(val: UserFilter[]) {
    console.log("set userFilters to value:", val);
    Vue.set(this.$_userInput, "userFilters", val);
  }

  get filterList() {
    const filterList: FilterInfo[] = this.filterInfos.reduce(
      (arr: FilterInfo[], item) => {
        if (
          this.userFilters.some(
            f => f.activeItem === item.name && f.activeValues.length === 0
          )
        )
          item.active = true;
        else item.active = false;
        // do not include top level filter item (those defines the values available of a filter type)
        // if (item.values.length <= 1) arr.push(item);
        if (Array.isArray(item.values)) {
          item.values.forEach(filterValue => {
            const subFilter: FilterInfo = {
              name: item.name,
              type: "default",
              values: [],
              itemValue: filterValue
            };
            if (
              this.userFilters.some(
                f =>
                  f.activeItem === item.name &&
                  f.activeValues.length > 0 &&
                  f.activeValues.includes(filterValue)
              )
            )
              subFilter.active = true;
            else subFilter.active = false;
            arr.push(subFilter);
          });
        }
        return arr;
      },
      []
    );
    // put active filter at first
    const activeFilterIdx = filterList.findIndex(filter => filter.active);
    if (activeFilterIdx > -1) {
      const activeFilterInfo = filterList.splice(activeFilterIdx, 1);
      filterList.unshift(...activeFilterInfo);
    }
    return filterList;
  }

  get showLayoutBtn() {
    return this.layout && this.layout != "none";
  }

  get showReturnBtn() {
    return this.layout === "none";
  }

  /** Component status */
  isSearchfieldExpand = false;
  isFilterPanelActive = false;

  scrollableFilterList: Scrollable | null = null;
  // filterListScroll = 0;
  // scrollEvent = -1;
  // @Watch("filterListScroll")
  // onFilterListScrolled(val: number) {
  //   if (this.scrollEvent > -1) {
  //     clearInterval(this.scrollEvent);
  //   }
  //   if (val > 0) {
  //     this.scrollEvent = setInterval(() => {
  //       scrollEle(this.elFilterList);
  //     }, 40);
  //   } else if (val < 0) {
  //     this.scrollEvent = setInterval(
  //       () => scrollEle(this.elFilterList, -10),
  //       40
  //     );
  //   }
  // }

  mounted() {
    this.scrollableFilterList = new Scrollable(
      this.elFilterList,
      "255, 255, 255",
      true,
      true
    );
    console.log("mounted & set scrollableFilter");
  }

  unmounted() {
    this.scrollableFilterList?.destroy();
  }

  /** Component control  */
  onSearchfieldClicked() {
    this.isSearchfieldExpand = !this.isSearchfieldExpand;
  }

  onSearchfieldBlurred() {
    return;
  }

  @Emit()
  onBtnReturnClicked() {
    return true;
  }

  @Emit()
  onBtnLayoutClicked() {
    return true;
  }

  @Emit()
  onBtnFilterClicked() {
    this.isFilterPanelActive = !this.isFilterPanelActive;
    return true;
  }

  onFilterItemClicked(filter: FilterInfo) {
    // console.log("onFilterItemClicked:", filter);
    // clean filter if it is active
    if (filter.active) {
      console.log("update filter:" + filter.name);
      const filterItem = this.userFilters.find(
        f => f.activeItem === filter.name
      );

      if (filterItem && filterItem.activeValues.includes(filter.itemValue)) {
        if (filterItem.activeValues.length === 1) {
          console.log("remove filter item");
          const newFilters = this.userFilters.filter(
            f => f.activeItem !== filter.name
          );
          this.userFilters = newFilters;
        } else {
          console.log("remove filter value: " + filter.itemValue);
          const newFilters = this.userFilters.map(f => {
            if (f.activeItem == filter.name) {
              return {
                activeItem: filter.name,
                activeValues: f.activeValues.filter(
                  val => val !== filter.itemValue
                )
              };
            }
            return f;
          });
          console.info(newFilters);
          this.userFilters = newFilters;
        }
      }
    } else {
      if (this.userFilters.some(f => f.activeItem === filter.name)) {
        console.log("add filter value: " + filter.itemValue);
        const newFilters = this.userFilters.map(f => {
          if (f.activeItem === filter.name) {
            return {
              activeItem: filter.name,
              activeValues: f.activeValues.concat([filter.itemValue])
            };
          }
          return f;
        });
        console.info(newFilters);
        this.userFilters = newFilters;
      } else {
        console.log("add filter item: " + filter.name);
        const newFilters = this.userFilters.concat([
          { activeItem: filter.name, activeValues: [filter.itemValue] }
        ]);
        console.info(newFilters);
        this.userFilters = newFilters;
      }
    }
  }
}
