
import { Component, Vue } from "vue-property-decorator";

@Component
export default class SummaryPanel extends Vue {
  // @Ref() containerDOM!: HTMLElement;
  // get containerWidth() {
  //   return this.containerDOM.offsetWidth;
  // }
  // @Watch("containerWidth")
  // onWidthChanged(val: number) {
  //   this.containerDOM.style.height = `${(val * 9) / 16}px`;
  // }
}
