import VueKeycloakJs from "@dsb-norge/vue-keycloak-js";
import Keycloak from "keycloak-js";
import Vue from "vue";
import App from "./App.vue";
Vue.config.productionTip = false;

Vue.use(VueKeycloakJs, {
  init: {
    onLoad: "login-required",
    silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
  },
  config: {
    url: process.env.VUE_APP_KC_URL,
    clientId: process.env.VUE_APP_KC_CLIENT_ID,
    realm: process.env.VUE_APP_KC_REALM
  },
  onReady(keycloak: Keycloak) {
    console.log(`Keycloak is ready! ${keycloak}`);
  }
});

new Vue({
  render: h => h(App)
}).$mount("#app");
