export interface ThemeConfig {
  primaryColor?: string;
  secondaryColor?: string;
}

export type FilterType = "Alert" | "";
export interface UserFilter {
  activeItem: FilterType;
  activeValues: any[];
}
export interface FilterInfo {
  name: FilterType;
  type: "default" | "identifier";
  values: any[];
  itemValue: any;
  active?: boolean;
  visible?: boolean;
}
export interface UserInputData {
  layout: "grid" | "list";
  timezone: string;
  searchText: string;
  userFilters: UserFilter[];
}

export interface CameraInfo {
  datetime: string;
  name: string;
  status: string;
  des: string;
  imgUrl: string;
  numAlert: number;
  url?: string;
}
export enum ALERT_LEVEL {
  NOTIFICATION = "NOTIFICATION",
  WARNING = "WARNING",
  ALERT = "ALERT",
  ALARM = "ALARM",
  ACTION = "ACTION"
}
export enum AlertBtnType {
  NOTIFICATION = "NOTIFICATION",
  WARNING = "WARNING",
  ALERT = "ALERT"
}
export interface IoTHubAAA {
  _event_id: string;
  sensorID: string;
  sensorType: string;
  sensorDisplayName: string;
  alertType: string;
  level: ALERT_LEVEL | "";
  timestamp: string;
  message: string;
  location: {
    latitude: number;
    longitude: number;
    altitude: number;
  };
  photoURLs: string[];
  highlight?: boolean;
  reported?: boolean;
  complete?: boolean;
}

export interface EventCenterData {
  id: string;
  profile_id: number;
  event_time: string; // iso8601
  message: IoTHubAAA;
}

export interface EventCenterResponse {
  data: EventCenterData[];
  total: number;
}

export interface WGS84 {
  longitude: number;
  latitude: number;
}

export interface HK1980 {
  hkN: number;
  hkE: number;
  hkPD: number;
}
