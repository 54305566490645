
import { AlertBtnType } from "@/utils/type";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class AlertBtnFilter extends Vue {
  @Prop({ default: () => AlertBtnType }) readonly AlertBtnType!: object;
  @Prop({ default: 999 }) readonly count!: number;
  @Prop({ default: AlertBtnType.NOTIFICATION }) readonly type!: AlertBtnType;
  @Prop({ default: false }) readonly active!: boolean;

  get href() {
    switch (this.type) {
      case AlertBtnType.NOTIFICATION:
        return "#notification";
      case AlertBtnType.WARNING:
        return "#warning";
      case AlertBtnType.ALERT:
        return "#alert";
      default:
        return "#";
    }
  }
  @Emit()
  click(e: Event) {
    // e.preventDefault();
    return this.type;
  }
}
