
import { ue4 } from "@/lib/ue4";
import { GenerateAlertDetail } from "@/utils/api";
import { setAltImage, WGS84ToHK80 } from "@/utils/helper";
import { AlertBtnType, ALERT_LEVEL, HK1980, IoTHubAAA } from "@/utils/type";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class AlertItem extends Vue {
  @Prop({ default: GenerateAlertDetail }) readonly data!: IoTHubAAA;

  expand = false;
  highlight = false;
  confirmText = "Acknowledgment";
  cancelText = "False alarm";

  get ALERT_LEVEL() {
    return ALERT_LEVEL;
  }

  get AlertBtnType() {
    return AlertBtnType;
  }

  get level() {
    return this.data.level;
  }

  get reported() {
    return this.data.reported;
  }

  get datetime() {
    return this.data.timestamp;
  }

  get thumbnail() {
    return Array.isArray(this.data.photoURLs) && this.data.photoURLs.length > 0
      ? this.data.photoURLs[0] || ""
      : "";
  }

  get itemClass() {
    return {
      expand: this.expand,
      highlight: this.highlight
    };
  }

  get alertBarStyle() {
    let color = "rgba(209, 33, 43, 0.5)";
    if (this.level === ALERT_LEVEL.NOTIFICATION) {
      color = "rgba(152, 223, 246, 0.5)";
    } else if (this.level === ALERT_LEVEL.WARNING) {
      color = "rgba(239, 189, 91, 0.5)";
    }
    return {
      backgroundColor: color
    };
  }

  @Emit("on-alert-complete")
  completeAlert() {
    return this.data._event_id;
  }

  @Emit("on-alert-acknowledged")
  acknowledgeAlert() {
    return this.data._event_id;
  }

  @Emit("on-alarm-false")
  falseAlarm() {
    return this.data._event_id;
  }

  @Emit()
  click() {
    return this.data._event_id;
  }

  @Emit()
  clickInspect() {
    return this.data._event_id;
  }

  @Emit()
  clickArrowLeft() {
    return -1;
  }

  setAltImg(event: any) {
    setAltImage(event);
  }

  onBtnClicked(action: string, event: Event) {
    switch (action) {
      case "alert":
        this.expand = !this.expand;
        if (this.expand) {
          ue4("open_alert", this.data);
        } else {
          ue4("close_alert", this.data);
        }
        break;
      case "location": {
        const [easting, northing] = WGS84ToHK80({
          longitude: this.data.location.longitude,
          latitude: this.data.location.latitude
        });
        const hk1980: HK1980 = {
          hkE: Math.round(easting) * 100,
          hkN: Math.round(northing) * 100,
          hkPD: Math.round(this.data.location.altitude)
        };
        ue4(`alert_${action}`, {
          ...hk1980,
          type: this.data.alertType,
          timestamp: this.data.timestamp
        });
        event.stopPropagation();
        break;
      }
      case "complete":
        this.completeAlert();
        event.stopPropagation();
        break;
      case "confirm":
        this.acknowledgeAlert();
        event.stopPropagation();
        break;
      case "cancel":
        this.falseAlarm();
        event.stopPropagation();
        break;
      default:
        ue4(`alert_${action}`, this.data);
        event.stopPropagation();
    }
  }

  created() {
    const params = new URLSearchParams(window.location.search);
    const confirmText = params.get("confirmText");
    const cancelText = params.get("cancelText");

    if (confirmText) {
      this.confirmText = confirmText;
    }

    if (cancelText) {
      this.cancelText = cancelText;
    }
  }
}
