
/* eslint-disable @typescript-eslint/no-explicit-any */

import { DEFAULT_THEME } from "@/utils/constants";
import { hexToRgb, setAltImage } from "@/utils/helper";
import { CameraInfo, ThemeConfig } from "@/utils/type";
import { Component, Prop, Emit, Vue } from "vue-property-decorator";

const EMPTY_CAMERAINFO: CameraInfo = {
  datetime: "2020/11/11 00:00:00",
  name: "undefined",
  status: "On",
  des: "",
  imgUrl: "",
  numAlert: 0
};

@Component
export default class CameraInfoItem extends Vue {
  @Prop({ default: () => DEFAULT_THEME }) readonly theme!: ThemeConfig;
  @Prop({ default: "list" }) readonly currentLayout!: string;
  @Prop({ default: false }) readonly active!: boolean;
  @Prop({ default: () => EMPTY_CAMERAINFO }) readonly camerainfo!: CameraInfo;

  get status() {
    return this.camerainfo.status;
  }

  get itemStyle() {
    let fgColor = "#4d4d4d";

    if (this.theme.primaryColor) {
      const rgb = hexToRgb(this.theme.primaryColor);
      if (rgb) {
        const brightness = Math.round(
          (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
        );
        if (brightness < 125) fgColor = "#ffffff";
      }
    }
    return {
      "--bg-color": "#ffffff",
      "--fg-color": "#4d4d4d",
      "--bg-color-hover": this.theme.primaryColor || "#ffffff",
      "--fg-color-hover": fgColor
    };
  }

  get layoutClass() {
    const classes: any = {};
    classes[this.currentLayout] = true;
    classes["active"] = this.active;
    return classes;
  }

  @Emit()
  click() {
    console.log(`Item ${this.camerainfo.name} is clicked`);
    return true;
  }

  @Emit()
  mouseenter() {
    return true;
  }

  showAltImg(event: any) {
    console.log("CameraInfoItem: setAltImg");
    setAltImage(event);
  }
}
