
import {
  Component,
  Prop,
  Emit,
  Ref,
  Vue,
  PropSync
} from "vue-property-decorator";

@Component
export default class ToolbarSearchField extends Vue {
  @Prop({ default: false }) readonly expand!: boolean;
  @Ref("searchbar") readonly searchbar!: HTMLInputElement;
  @PropSync("searchText", { type: String }) readonly text!: string;

  @Emit()
  click() {
    if (!this.expand) {
      console.log("focus on search bar");
      this.searchbar.focus();
    }
    return true;
  }

  @Emit()
  blur() {
    return true;
  }
}
