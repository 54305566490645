import proj4 from "proj4";
import { WGS84 } from "./type";

export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

/**
 * Format a date like YYYY-MM-DD.
 *
 * @param {string} template
 * @param {Date=} [date]
 * @return {string}
 * @license MIT
 */
export const formatDate = (
  template: string,
  date?: Date | number | string | null
) => {
  const specs = "YYYY:MM:DD:HH:mm:ss".split(":");
  try {
    const fdate = new Date(
      date || Date.now() - new Date().getTimezoneOffset() * 6e4
    );
    return fdate
      .toISOString()
      .split(/[-:.TZ]/)
      .reduce(function(template, item, i) {
        return template.split(specs[i]).join(item);
      }, template);
  } catch (err) {
    console.warn(`formatDate: fail to format ${date} to template`);
    console.warn(err);
    return date ? date.toString() : "undefined";
  }
};

export const toISODateString = (date: Date) => {
  const tzo = -date.getTimezoneOffset(),
    dif = tzo >= 0 ? "+" : "-",
    pad = function(num: number) {
      return (num < 10 ? "0" : "") + num;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(0) +
    ":" +
    pad(0) +
    ":" +
    pad(0) +
    dif +
    pad(Math.floor(Math.abs(tzo) / 60)) +
    ":" +
    pad(Math.abs(tzo) % 60)
  );
};

export const setAltImage = (event: any) => {
  // event.onerror = "";
  event.target.src = require("@/assets/no-image.png");
  event.target.style = "width: 100%;";
};

export const scrollEle = (ele: HTMLElement, vel = 10) => {
  ele.scrollLeft += vel;
};

export const getHorizontalHoverScrollDirection = (
  e: MouseEvent,
  ele: HTMLElement,
  areaWidth: number
): 1 | -1 | 0 => {
  // console.log(e);
  const rect = ele.getBoundingClientRect();
  const elementWidth = rect.width;
  const relativeX = e.pageX - rect.left - document.body.scrollLeft;
  const scrollLeft = ele.scrollLeft;
  const scrollWidth = ele.scrollWidth;
  const offset = ele.offsetWidth;
  // console.log(
  //   `scrollLeft ${scrollLeft}, scrollWidth ${scrollWidth}, offset ${offset}`
  // );
  if (elementWidth - relativeX < areaWidth) {
    if (scrollWidth - scrollLeft === offset) return 0;
    return 1;
  } else if (relativeX < areaWidth && scrollLeft) {
    return -1;
  }
  return 0;
};

export class Scrollable {
  container: HTMLElement;
  areaWidth: number;
  canScrollOnHover: boolean;
  canScrollOnWheel: boolean;
  velocity: number;
  _handle = -1;
  _inertia = 0;
  _leftHoverEl: HTMLElement;
  _rightHoverEl: HTMLElement;
  _offset: number;
  _backgroundColor: string;

  constructor(
    ele: HTMLElement,
    backgroundColor = "123, 123, 123",
    canScrollOnHover = false,
    canScrollOnWheel = true,
    velocity = 10,
    areaWidth = 70
  ) {
    if (!ele) {
      throw new Error("Invalid HTMLElement");
    }
    this.container = ele;
    this.canScrollOnHover = canScrollOnHover;
    this.canScrollOnWheel = canScrollOnWheel;
    this.velocity = velocity;
    this.areaWidth = areaWidth;
    this._offset = 0;
    this._backgroundColor = backgroundColor;

    this._leftHoverEl = document.createElement("div");
    this._rightHoverEl = document.createElement("div");

    this._leftHoverEl.className = "leftHover";
    this._leftHoverEl.classList.add("hidden");
    this._rightHoverEl.className = "rightHover";

    this.container.classList.add("scrollable");
    this.container.style.setProperty("--color", this._backgroundColor);

    this._leftHoverEl.addEventListener("mouseover", () => {
      // console.log("mouseover left")
      this.scrollOnHover(-1);
    });
    this._leftHoverEl.addEventListener("mouseleave", () => {
      // console.log("mouseleave left")
      this.stop();
    });
    this._rightHoverEl.addEventListener("mouseover", () => {
      // console.log("mouseover right")
      this.scrollOnHover(1);
    });
    this._rightHoverEl.addEventListener("mouseleave", () => {
      // console.log("mouseleave right")
      this.stop();
    });
    this.container.appendChild(this._leftHoverEl);
    this.container.appendChild(this._rightHoverEl);
    // this.container.addEventListener("mouseover", (ev: MouseEvent) => {
    //   this.scrollOnHover(ev);
    // });

    // this.container.addEventListener("mouseleave", () => {
    //   this.stop();
    // });
    this.container.addEventListener("wheel", (ev: WheelEvent) => {
      this.scrollOnWheel(ev);
    });
    this.updateStyle();
  }

  destroy() {
    if (this.container) {
      this.container.classList.remove("scrollable");
      if (this._leftHoverEl) this.container.removeChild(this._leftHoverEl);
      if (this._rightHoverEl) this.container.removeChild(this._rightHoverEl);

      this.container.removeEventListener("wheel", (ev: WheelEvent) => {
        this.scrollOnWheel(ev);
      });
    }
  }

  updateStyle() {
    this._offset = this.container.offsetWidth + this.container.scrollLeft;
    // console.log(`${this._offset} / ${this.container.scrollWidth}`);
    if (this._offset >= this.container.offsetWidth + 2 && this._leftHoverEl) {
      this._leftHoverEl.classList.remove("hidden");
    } else if (this._leftHoverEl) {
      this._leftHoverEl.classList.add("hidden");
    }
    if (this._offset >= this.container.scrollWidth && this._rightHoverEl) {
      this._rightHoverEl.classList.add("hidden");
    } else if (this._rightHoverEl) {
      this._rightHoverEl.classList.remove("hidden");
    }
  }

  scroll(vel: number) {
    // console.log("scroll")
    this.container.scrollLeft += vel;
    this.updateStyle();
  }

  scrollOnWheel(e: WheelEvent) {
    if (!this.canScrollOnWheel) return;
    let delta = 0;
    if (e.deltaX > 0 || e.deltaX < 0) {
      delta = e.deltaX;
    } else if (e.deltaY > 0 || e.deltaY < 0) {
      delta = e.deltaY;
    }

    if (delta) {
      this.scroll((delta / 100) * this.velocity * 50);
    }
  }

  scrollOnHover(direction: number) {
    if (!this.canScrollOnHover) return;
    if (this._inertia === direction) {
      // console.log("no change in scroll direction");
      return;
    }
    if (this._inertia != direction && this._handle > -1) {
      // console.log("clear interval", this._handle);
      this.stop();
    }
    console.log(`current direction: ${direction > 0 ? "right" : "left"}`);
    this._inertia = direction;
    if (direction > 0) {
      this._handle = window.setInterval(() => this.scroll(this.velocity), 40);
      // console.log("scroll right, set handler to", this._handle);
    } else if (direction < 0) {
      this._handle = window.setInterval(() => this.scroll(-this.velocity), 40);
      // console.log("scroll left, set handler to", this._handle);
    }
  }

  stop() {
    // console.log("stop scrolling.")
    if (this._handle > -1) {
      // console.log("clear interval", this._handle);
      clearInterval(this._handle);
      this._handle = -1;
      this._inertia = 0;
    }
  }
}

// WGS1984
proj4.defs(
  "WGS84",
  "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees"
);
// HK1980
proj4.defs(
  "EPSG2326",
  "+proj=tmerc +lat_0=22.31213333333334 +lon_0=114.1785555555556 +k=1 +x_0=836694.05 +y_0=819069.8 +ellps=intl +towgs84=-162.619,-276.959,-161.764,0.067753,-2.24365,-1.15883,-1.09425 +units=m +no_defs"
);
// UTM Zone 50
proj4.defs("EPSG32632", "+proj=utm +zone=50");

export const WGS84ToHK80 = (loc: WGS84) => {
  const coordinatesHK80 = proj4("WGS84", "EPSG2326", [
    loc.longitude,
    loc.latitude
  ]);
  return coordinatesHK80;
};

export const WGS84ToUTM = (loc: WGS84) => {
  const coordinateUTM = proj4("WGS84", "EPSG32632", [
    loc.longitude,
    loc.latitude
  ]);
  return coordinateUTM;
};
